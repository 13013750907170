<template>
  <v-card class="profile-property" v-bind="$attrs">
    <v-card-title class="pa-4 pa-md-6">
      <h3 class="text-h6 ">{{ $t('profile.property') }} {{ number }}</h3>
    </v-card-title>

    <project-info :project="project" :unit="unit" :building="building" />

    <profile-inventory v-if="hasInventory" :inventory="inventory" />
    <div v-else class="profile-property__inventory-stub"></div>
  </v-card>
</template>

<script>
import ProjectInfo from './ProjectInfo.vue';
import ProfileInventory from './Inventory.vue';

export default {
  name: 'ProfileProperty',

  components: { ProjectInfo, ProfileInventory },

  props: {
    number: { type: Number, required: true },
    project: { type: String, required: true },
    building: { type: String, required: true },
    unit: { type: String, required: true },
    inventory: { type: Array, required: true },
  },

  computed: {
    hasInventory() {
      return this.inventory.length > 0;
    },
  },
};
</script>

<style lang="scss">
.profile-property {
  &__inventory-stub {
    height: 56px;
  }
}
</style>
