<template>
  <v-list-group no-action sub-group class="profile-inventory flex-column-reverse" :ripple="false">
    <div class="px-4 px-md-6">
      <v-divider />
    </div>
    <v-list-item class="px-4 px-md-6">
      <v-row no-gutters>
        <v-col
          v-for="(item, index) in inventory"
          :key="item.id"
          class="pt-6"
          :class="{
            'pr-md-3': checkIsEven(index),
            'pl-md-3': !checkIsEven(index),
          }"
          cols="12"
          md="6"
        >
          <v-row class="mb-4">
            <v-col cols="6">
              <ViewField :label="$t('unit.title')">
                {{ item.title }}
              </ViewField>
            </v-col>
            <v-col cols="6">
              <ViewField :label="$t('unit.quantity')">
                {{ item.quantity }}
              </ViewField>
            </v-col>
          </v-row>

          <div class="profile-inventory__image-container">
            <view-field-image-list :value="item.images" :payload="{ listTitle: item.title }" />
          </div>
        </v-col>
      </v-row>
    </v-list-item>
  </v-list-group>
</template>

<script>
import ViewFieldImageList from '@/components/schema/ViewFieldImageList.vue';
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'ProfileInventory',

  components: { ViewFieldImageList, ViewField },

  props: {
    inventory: { type: Array, required: true },
  },

  methods: {
    checkIsEven(number) {
      return number % 2 === 0;
    },
  },
};
</script>

<style lang="scss">
.profile-inventory {
  .v-list-group__header {
    justify-content: center;
    width: 100%;
  }

  .v-list-group__items {
    width: 100%;
  }

  &__image-container {
    @media (max-width: map.get($--screens, 'sm')) {
      display: flex;
      overflow-y: hidden;
      overflow-x: scroll;
    }
  }

  &__image-container > * {
    flex-shrink: 0;
  }
}
</style>
