<template>
  <v-row v-bind="$attrs">
    <v-col class="mb-8 py-0" cols="12" md="6" lg="4" xl="3">
      <view-field :label="$t('label.name')">
        {{ name }}
      </view-field>
    </v-col>
    <v-col class="mb-8 py-0" cols="12" md="3" lg="3" xl="3">
      <view-field :label="$t('label.email')">
        {{ email }}
      </view-field>
    </v-col>
    <v-col class="py-0" cols="12" md="3" lg="3" xl="3">
      <view-field :label="$t('label.mobile')">
        {{ phone }}
      </view-field>
    </v-col>
  </v-row>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'ProfileContacts',

  components: { ViewField },

  props: {
    name: { type: String, default: '' },
    email: { type: String, default: '' },
    phone: { type: String, default: '' },
  },
};
</script>
