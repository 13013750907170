<template>
  <div>
    <profile-header @logout="logout" />

    <profile-contacts class="mb-6 mb-md-16" :name="fullName" :email="client.email" :phone="client.phone" />

    <profile-address v-if="hasProjects" :projects="client.usages" />
  </div>
</template>

<script>
// node_modules
import { mapActions, mapState } from 'vuex';

// Constants
import { LOGIN } from '@/constants/routes';
import { LOGOUT } from '@/store/modules/user/actions/types';
import { LOG_OUT as analyticsActionLogout, PROFILE } from '@/constants/analyticsActions';

// Components
import ProfileContacts from '@/components/Profile/Contacts.vue';
import ProfileAddress from '@/components/Profile/Address.vue';
import ProfileHeader from '@/components/Profile/Header.vue';

// Services
import analyticsService from '@/services/analytics';

export default {
  name: 'Profile',

  components: { ProfileContacts, ProfileAddress, ProfileHeader },

  computed: {
    ...mapState({
      client: state => state.user.user,
    }),

    fullName() {
      if (!this.client.firstName) return '';
      return `${this.client.firstName} ${this.client.lastName}`;
    },

    hasProjects() {
      return !!this.client.usages;
    },
  },

  mounted() {
    analyticsService.track(PROFILE);
  },

  methods: {
    ...mapActions('user', {
      userLogout: LOGOUT,
    }),

    logout() {
      analyticsService.track(analyticsActionLogout);
      this.userLogout();
      this.$router.push({ name: LOGIN });
    },
  },
};
</script>
