<template>
  <div class="d-flex justify-space-between align-center mb-10">
    <h1 class="text-h4 font-weight-bold">{{ $t('profile.profile') }}</h1>

    <div class="d-flex">
      <v-btn large color="secondary" class="primary--text elevation-0 font-weight-bold" @click="logout">
        <v-icon left color="primary">
          mdi-logout-variant
        </v-icon>

        {{ $t('profile.logout') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileHeader',

  methods: {
    logout() {
      this.$emit('logout');
    },
  },
};
</script>
