<template>
  <div class="profile-address" v-bind="$attrs">
    <h2 class="mb-10">{{ $t('profile.address_information') }}</h2>

    <profile-property
      v-for="({ unit, project, building }, index) in projects"
      :key="project.id"
      class="mb-6 mb-md-8"
      :number="index + 1"
      :unit="unit.name"
      :project="project.name"
      :building="building.name"
      :inventory="unit.inventory"
    />
  </div>
</template>

<script>
import ProfileProperty from './Property.vue';

export default {
  name: 'ProfileAddress',

  components: { ProfileProperty },

  props: {
    projects: { type: Array, required: true },
  },
};
</script>
