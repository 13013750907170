<template>
  <v-card-text class="px-4 px-md-6 pb-4">
    <v-row>
      <v-col cols="12" md="6">
        <view-field :label="$t('label.project')">
          {{ project }}
        </view-field>
      </v-col>
      <v-col cols="6" md="3">
        <view-field :label="$t('label.building')">
          {{ building }}
        </view-field>
      </v-col>
      <v-col cols="6" md="3">
        <view-field :label="$t('label.unit')" :align="media.isMobile ? 'right' : 'left'">
          {{ unit }}
        </view-field>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'ProfileProjectInfo',

  components: { ViewField },

  inject: ['media'],

  props: {
    project: { type: String, required: true },
    building: { type: String, required: true },
    unit: { type: String, required: true },
  },
};
</script>
